/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button } from 'antd';
import { SearchOutlined } from "@ant-design/icons";

const SuffixIcon = ({ children, icon = <SearchOutlined />, size = "small", onClick, ...props }) => {
    const style = {
        display: 'flex',
        alignItems: 'center',
        flexGrow: '1'
    }

    const newChildren = React.cloneElement(children, { style: { flexGrow: 1, overflow: 'hidden', ...children.style }, size })
    return (
        <div style={style}>
            {newChildren}
            <Button type="link" icon={icon} size={size} onClick={onClick} style={{ flexShrink: 0, marginLeft: '2px', width: '28px' }} {...props} />
        </div>
    )
}

export default SuffixIcon;
