/** @jsxImportSource @emotion/react */
import Tools from "../../script/sweet-tools";

;
import {Layout, Tooltip, Typography} from 'antd';
import MainMenu from './MainMenu';
// import iconLogo from 'public/favicon.svg';
import iconLogo from 'public/favicon.ico';
import {UnorderedListOutlined} from "@ant-design/icons";
import React from "react";

const MainTitle = () => {
    const {Text, Link} = Typography;
    const user = Tools.authenticator.getUser();
    const cssTitle = {
        fontWeight: 'bold',
        padding: '0 12px',
        flexShrink: 0,
        float: 'left',
    };
    const cssSubTitle = {
        fontSize: 14,
        flexShrink: 0,
        float: 'left',
    };
    const cssLogo = {
        height: 32,
        marginRight: 10,
    };

    return (
        <div >
        <div css={cssTitle}>
                <img
                    css={cssLogo}
                    alt="logo"
                    src={iconLogo}
                />
            服装贸易数字化运营平台
        </div>
        {/*<div css={cssSubTitle}>*/}
        {/*    <Tooltip placement="top" title={<span><div>{user.org?.orgPathName}</div><div>{user.roleNames}</div></span>}>*/}
        {/*        <Text>欢迎您，{user.emp.empName} 「{user.org?.orgPathNameAbbr}」</Text>*/}
        {/*    </Tooltip>*/}
        {/*</div>*/}
       </div>
    );
};

const MainHeader = () => {
    const cssHeader = {
        height: '46px',
        lineHeight:'46px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
        background: '#fff',
        borderBottom: '1px solid #f0f0f0'
    };

    return (
        <Layout.Header css={cssHeader}>
            <MainTitle />
            <MainMenu />
        </Layout.Header>
    );
};

export default MainHeader;
