

//至少保留两位小数,返回字符串
export const declPlaceTemStr = (value, num = 2) => {
	if (value === null || value === undefined) {
		return value;
	}
	const str = value + '';
	const decimalCount = str.includes('.') ? str.split('.')[1].length : 0;
	if (decimalCount === 0) {
		return Number(value).toFixed(num);
	} else if (decimalCount < num) {
		return Number(value).toFixed(num);
	} else {
		return Number(value).toFixed(decimalCount);
	}
}
//保留两位小数,返回字符串
export const textTemStr = (text, num = 2) => {
	return (text || text === 0) ? (Number(text).toFixed(num)) : '';
}

//保留两位小数,返回数字
export const forNumberJ = (value, num = 2) => {
	if (value === null || value === undefined || num < 0) {
		return value;
	}
	return (value || value === 0) ? (Number(Number(value).toFixed(num))) : null;
}

//有几位小数就保留几位小数,至少保留两位小数,返回数字
export const forNumberD = (value, num = 2) => {
	if (value === null || value === undefined) {
		return value;
	}
	return Number(declPlaceTemStr(value, num));
}


