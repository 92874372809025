import {Table} from 'antd'
import fixNum from 'script/fixNum.mjs'
import getColumnValue from './getColumnValue.mjs'

const enhanceSummary = ({
    columns,
    noRowNumber,
    rows,
    rowSelection,
    summary,
                            sumNumberRD,
}) => {
    if (summary) {
        return (rows) => summary(rows, columns)
    }
    else {
        const Summary = () => {
            if (0 === rows.length) {
                return null
            }

            const cells = []
            let index = 0

            if (! noRowNumber || rowSelection) {
                const colSpan = ! noRowNumber && rowSelection ? 2 : 1

                cells.push(
                    <Table.Summary.Cell
                        align="center"
                        colSpan={colSpan}
                        index={index}
                    >
                        合计
                    </Table.Summary.Cell>
                )

                index += colSpan
            }

            let hasSummary = false

            for (const column of columns) {
                const {align, dataIndex, summary} = column

                const value = (() => {
                    if (summary) {
                        hasSummary = true

                        const values = rows.map(
                            (row) => getColumnValue(row, dataIndex)
                        )

                        if ('function' === typeof summary) {
                            return summary(values)
                        }
                        else {
                            return fixNum(
                                values.reduce(
                                    (sum, value) => sum + (value ?? 0),
                                    0
                                ),

                                3
                            )
                        }
                    }
                    else {
                        return ''
                    }
                })()

                cells.push(
                    <Table.Summary.Cell
                        align={align}
                        index={index}
                    >
                        { (value||value===0) ?(sumNumberRD? Number(value).toFixed(sumNumberRD):value) : ""}
                    </Table.Summary.Cell>
                )

                index += 1
            }

            if (hasSummary) {
                return (
                    <Table.Summary>
                        <Table.Summary.Row>{cells}</Table.Summary.Row>
                    </Table.Summary>
                )
            }
            else {
                return null
            }
        }

        return Summary
    }
}

export default enhanceSummary
