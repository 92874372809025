/** @jsxImportSource @emotion/react */
import {Alert} from 'antd';
import Marquee from "react-fast-marquee";
import {soBudgettnpChgWarn} from "../../SM/SM30_DT_SM_CSO_LIST/Valid/soApi.mjs";
import React, {useEffect, useState} from 'react';


const Pane = ({soId, value = 2, cssStyle, ...props}) => {


    const [chgWarnValue, setChgWarnValue] = useState("")
    const [tnpChgWarnFlag, setTnpChgWarnFlag] = useState(false)

    useEffect(() => {
        // `净利润额减少超2万，请重新提交预算，否则将影响订单后续操作！`
        init();
    }, [soId])

    const init = async () => {

        try {

            if (soId) {
                const data = await soBudgettnpChgWarn({soId});
                if (data) {

                    if (data?.tnpChgWarnFlag) {

                        const tnpChgWarn = data?.tnpChgWarn;
                        if (Number(tnpChgWarn) <= 0) {
                            setChgWarnValue(`净利润额减少${Math.abs(tnpChgWarn)}万！`);
                        } else {
                            setChgWarnValue(`净利润额增加${Math.abs(tnpChgWarn)}万！`);
                        }
                        setTnpChgWarnFlag(true);

                    } else {
                        setTnpChgWarnFlag(false);
                    }

                } else {
                    setTnpChgWarnFlag(false)
                }
            } else {

                setTnpChgWarnFlag(true);

                if (Number(value) <= 0) {
                    setChgWarnValue(`净利润额减少${Math.abs(value)}万，请重新提交预算，否则将影响订单后续操作！`);
                } else {
                    setChgWarnValue(`净利润额增加${Math.abs(value)}万，请重新提交预算，否则将影响订单后续操作！`);
                }

            }

        } catch (err) {

        } finally {

        }

    }


    return <>
        {
            soId ? <div style={{
                    backgroundColor: "#e6f7ff",
                    marginLeft: "500px",
                    border: "none",
                    color: "red",
                    fontWeight: "bold",
                    ...cssStyle
                }}>{chgWarnValue}</div> :
                tnpChgWarnFlag &&
                <div style={{width: "40%"}}>
                    <Alert
                        banner
                        style={{backgroundColor: "#e6f7ff", border: "none", ...cssStyle}}
                        message={
                            <Marquee pauseOnHover speed={10} gradient={false}
                                     style={{color: "red", fontWeight: "bold"}}>
                                {chgWarnValue}
                            </Marquee>
                        }
                    />
                </div>}
    </>

}

export default Pane;
