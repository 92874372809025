import useGlobalData from "../hooks/useGlobalData.mjs";
import {nanoid} from "nanoid";
import Tools from "./sweet-tools.mjs";

let cache = null

const authenticator = () => {
}

authenticator.checkStrong = (value) => {
    var pattern = /^.*(?=.{8,16})(?=.*\d)(?=.*[A-Z]{1})(?=.*[a-z]{1,})(?=.*[!@#$%^&*?\(\)]).*$/
    return pattern.test(value);
}

authenticator.strongName = (level) => {
    switch (level) {
        case 1:
            return '弱' //强度为1
        case 2:
            return '一般' //强度为2
        case 3: //强度为3
            return '中'
        case 4: //强度为4
            return '强'
    }
}

authenticator.get = () => {
    if (! cache) {
        const s = localStorage.getItem(process.env.REACT_APP_SESSION)

        if (s) {
            cache = JSON.parse(s)
        }
    }

    return cache
}

authenticator.set = (values) => {
    cache = values

    if (values) {
        window.localStorage.setItem(process.env.REACT_APP_SESSION, JSON.stringify(values));
    } else {
        window.localStorage.removeItem(process.env.REACT_APP_SESSION)
    }
}

authenticator.clear = () => {
    cache = null
    window.localStorage.removeItem(process.env.REACT_APP_SESSION)
}

authenticator.getUser = () => {
    const authObj = authenticator.get();
    return authObj?.user;
}

/**
 * 获取默认核算组 ID
 * 规则：
 * 1.用户没有核算组时，返回 null
 * 2.用户有核算组时，且只有1个时，返回该核算组 ID
 * 3.用户有核算组时，且有多个时，返回当前排序下的第一个核算组 ID
 * 4.billTypeCode 单据类型编码，若传值，将检查该单据是否需要核算组编码，若是，返回核算将剔除编码为空的记录
 *   permissionCode 权限编码
 *   permissionOptCode 权限操作编码
 * @returns {null|*}
 */
authenticator.getDefaultPuId = (billTypeCode, permissionCode, permissionOptCode="query") => {
    const pu = authenticator.getDefaultPu(billTypeCode,permissionCode,permissionOptCode);
    if (pu) {
        return pu.puId;
    } else {
        return null;
    }
}

/**
 * 获取默认核算组
 * 规则：
 * 1.用户没有核算组时，返回 null
 * 2.用户有核算组时，且只有1个时，返回该核算组
 * 3.用户有核算组时，且有多个时，返回当前排序下的第一个核算组
 * 4.billTypeCode 单据类型编码，若传值，将检查该单据是否需要核算组编码，若是，返回核算将剔除编码为空的记录
 *   permissionCode 权限编码
 *   permissionOptCode 权限操作编码
 * @returns {null|*}
 */
authenticator.getDefaultPu = (billTypeCode,permissionCode,permissionOptCode="query") => {
    const authObj = authenticator.get();
    let useabledPus = [];
    if ( billTypeCode ){
        let billTypes = useGlobalData.getDictDtls("BILL_TYPE");
        let needPuNoBillTypes = billTypes.filter(item=>{
            return (item.dictItemCode === billTypeCode && item.memo ==='NEED_PU_NO');
        });
        if ( needPuNoBillTypes && needPuNoBillTypes.length > 0){
            //返回核算组编码不为空的记录
            if ( authObj && authObj.user && authObj.user.pus && authObj.user.pus.length > 0 ){
                useabledPus =  authObj.user.pus.filter(item => {
                    return ( item.puCode !=null && item.puCode !='')
                });
            }
        }
    }else{
        if ( authObj && authObj.user && authObj.user.pus ){
            useabledPus =  authObj.user.pus;
        }
    }
    if (permissionCode && permissionOptCode) {
        let permissionedPus = useabledPus.filter(item => {
            return Tools.authenticator.checkComponentPermissionScope({
                permissionCode,
                permissionOptCode,
                sourceObject: item,
                sourceType: 'PU'
            })
        });
        return  permissionedPus && permissionedPus.length > 0 ? permissionedPus [0] : null;
    }
    return useabledPus && useabledPus.length > 0 ? useabledPus[0] : null;
}

authenticator.getGuest = () => {
    const authObj = authenticator.get();
    return authObj?.guest;
}

authenticator.getTokenInfo = () => {
    const authObj = authenticator.get();
    return authObj?.tokenInfo;
}

authenticator.getToken = () => {
    const authObj = authenticator.get();
    return authObj?.tokenInfo?.token;
}

authenticator.verify = () => {
    const authObj = authenticator.get();
    if (authObj) {
        const {token, tokenExpires, refreshToken, refreshTokenExpires} = authObj.tokenInfo;
        if (tokenExpires > new Date().getTime()) {
            return true;
        }
    }
    return false;
}

authenticator.verifyRefreshToken = () => {
    const authObj = authenticator.get();
    if (authObj) {
        const {token, tokenExpires, refreshToken, refreshTokenExpires} = authObj.tokenInfo;
        if (refreshTokenExpires > new Date().getTime()) {
            return true;
        }
    }
    return false;
}


/**
 * 校验权限范围
 * 适用于组织控件、核算组控件、人员控件的内部数据权限控制
 * @param permissionCode
 * @param permissionOptCode
 * @param sourceObject
 * @param sourceType ORG | PU | EMP
 * @returns {boolean}
 */
authenticator.checkComponentPermissionScope = ({permissionCode, permissionOptCode, sourceObject, sourceType}) => {
    const user = authenticator.getUser();
    const isAdmin = user.adminFlag || user.superFlag;
    //如果是管理员，默认为有权限
    if (isAdmin) {
        return true;
    }

    //未传编码，默认返回 true
    if (!permissionCode || !permissionOptCode) {
        return true;
    }

    const permissionMap = user.permissionMap;
    const permissionKey = permissionCode + "_" + permissionOptCode;
    const permission = permissionMap[permissionKey];
    let hasPermission = false;

    try {
        if (permission) {
            const {compareType, orgs, pus, users} = permission;
            if (sourceType === 'ORG') {
                //来源为组织，计算组织权限
                const orgPathNo = sourceObject.orgPathNo;
                if (compareType === 'ORG') {
                    for (let org of orgs) {
                        if (orgPathNo.startsWith(org)) {
                            hasPermission = true;
                            break;
                        }
                    }
                } else if (compareType === 'PU') {
                    for (let puId of pus) {
                        const pu = useGlobalData.getPu(puId);
                        if (orgPathNo.startsWith(useGlobalData.getOrgPathNo(pu.orgId))) {
                            hasPermission = true;
                            break;
                        }
                    }
                } else if (compareType === 'EMP') {
                    for (let user1 of users) {
                        if (orgPathNo.startsWith(useGlobalData.getOrgPathNo(user1.orgId))) {
                            hasPermission = true;
                            break;
                        }
                    }
                }
            } else if (sourceType === 'PU') {
                //来源为核算组，计算核算组权限
                if (compareType === 'ORG') {
                    const orgPathNo = useGlobalData.getOrgPathNo(sourceObject.orgId)
                    for (let org of orgs) {
                        if (orgPathNo.startsWith(org)) {
                            hasPermission = true;
                            break;
                        }
                    }
                } else if (compareType === 'PU') {
                    hasPermission = pus.includes(sourceObject.puId);
                } else if (compareType === 'EMP') {
                    const puUsers = useGlobalData.getBasEmpsByPuId(sourceObject.puId);
                    for (let puUsersId of puUsers) {
                        if (users.includes(puUsersId)) {
                            hasPermission = true;
                            break;
                        }
                    }
                }
            } else if (sourceType === 'EMP') {
                //来源为人员
                if (compareType === 'ORG') {
                    const orgPathNo = sourceObject.basOrg.orgPathNo;
                    for (let org of orgs) {
                        if (orgPathNo.startsWith(org)) {
                            hasPermission = true;
                            break;
                        }
                    }
                } else if (compareType === 'PU') {
                    const userPus = sourceObject.basPus;
                    if (userPus) {
                        for (let userPu of userPus) {
                            if (pus.includes(userPu.puId)) {
                                hasPermission = true;
                                break;
                            }
                        }
                    }
                } else if (compareType === 'EMP') {
                    hasPermission = users.includes(sourceObject.empId);
                }
            } else {
                //来源未知
            }
        }
    } catch (e) {
        const logId = nanoid();
        console.log('-----权限校验异常 Start-----', logId)
        console.log(e)
        console.log('-----权限校验异常 End-----', logId)
    }

    return hasPermission;
}

/**
 * 是否具有权限
 * 1.当只传权限码时，只会判断权限码是否存在于用户的权限集合中，适用查询权限判断
 * 2.当额外传递单据属性时，会先判断权限是否存在，再校验值是否在范围内
 * @param permissionCode 功能权限码
 * @param permissionOptCode 操作权限码
 * @param billOrgId 制单组织ID，会自动转为全路径
 * @param billOrgPathNo 制单组织全路径
 * @param billPuId 制单核算组
 * @param billUserId 制单用户
 * @returns {boolean} 当有权限时返回 true，权限不足时返回 false;
 */
authenticator.hasPermission = ({permissionCode, permissionOptCode, billOrgId, billOrgPathNo, billPuId, billUserId}) => {
    let hasPermission = false;
    if (permissionCode && permissionOptCode) {
        const user = authenticator.getUser();
        const isAdmin = user.adminFlag || user.superFlag;
        //如果是管理员，默认为有权限
        if (isAdmin) {
            return true;
        }

        const permissionMap = user.permissionMap;
        const permissionKey = permissionCode + "_" + permissionOptCode;
        const permission = permissionMap[permissionKey];
        if (permission) {
            //当传了这3个参数中的一个，就继续校验值范围
            if (billOrgId || billOrgPathNo || billPuId || billUserId) {
                if (billOrgId) {
                    billOrgPathNo = useGlobalData.getOrgPathNo(billOrgId);
                }
                //获取用户对应功能模块+操作权限的权限范围信息
                const {compareType, orgs, pus, users} = permission;
                if (Array.isArray(billOrgPathNo) || Array.isArray(billPuId) || Array.isArray(billUserId)) {
                    //批量判断，必须全部包含才能算有权限，与单个比对是反向规则

                    if (compareType === 'ORG') {
                        //比对类型 = 组织，判断 billOrgId 是否在当前用户的 功能+操作+范围内
                        hasPermission = true;
                        for (let billOrgPathNoElement of billOrgPathNo) {
                            let isMatching = false;
                            for (let org of orgs) {
                                if (billOrgPathNoElement.startsWith(org)) {
                                    isMatching = true;
                                    break;
                                }
                            }

                            if (!isMatching) {
                                hasPermission = isMatching;
                                break;
                            }
                        }
                    } else if (compareType === 'PU') {
                        //比对类型 = 核算组，判断 billPuId 是否在当前用户的 功能+操作+范围内
                        hasPermission = true;
                        for (let billPuIdElement of billPuId) {
                            let isMatching = pus.includes(billPuIdElement);
                            if (!isMatching) {
                                hasPermission = isMatching;
                                break;
                            }
                        }
                    } else if (compareType === 'EMP') {
                        //比对类型 = 人员，判断 billUserId 是否在当前用户的 功能+操作+范围内
                        hasPermission = true;
                        for (let billUserIdElement of billUserId) {
                            let isMatching = users.includes(billUserIdElement);
                            if (!isMatching) {
                                hasPermission = isMatching;
                                break;
                            }
                        }
                    }
                } else {
                    if (compareType === 'ORG') {
                        //比对类型 = 组织，判断 billOrgId 是否在当前用户的 功能+操作+范围内
                        for (let org of orgs) {
                            if (billOrgPathNo && billOrgPathNo.startsWith(org)) {
                                hasPermission = true;
                                break;
                            }
                        }
                    } else if (compareType === 'PU') {
                        //比对类型 = 核算组，判断 billPuId 是否在当前用户的 功能+操作+范围内
                        hasPermission = pus.includes(billPuId)
                    } else if (compareType === 'EMP') {
                        //比对类型 = 人员，判断 billUserId 是否在当前用户的 功能+操作+范围内
                        hasPermission = users.includes(billUserId)
                    }
                }
            } else {
                //没有传单据数据时，直接返回
                hasPermission = true;
            }
        }
    }
    return hasPermission;
}

/**
 * 是否具有查询权限
 * @param params
 * @returns {boolean} 当有权限时返回 true，权限不足时返回 false;
 */
authenticator.hasPermissionQuery = (params) => {
    return authenticator.hasPermission({...params, permissionOptCode: 'query'});
}

/**
 * 是否具有编制权限
 * @param params
 * @returns {boolean} 当有权限时返回 true，权限不足时返回 false;
 */
authenticator.hasPermissionEdit = (params) => {
    return authenticator.hasPermission({...params, permissionOptCode: 'edit'});
}

/**
 * 是否具有审核权限
 * @param params
 * @returns {boolean} 当有权限时返回 true，权限不足时返回 false;
 */
authenticator.hasPermissionVerify = (params) => {
    return authenticator.hasPermission({...params, permissionOptCode: 'verify'});
}

/**
 *是否具有取消审核权限
 * @param params
 * @returns {boolean} 当有权限时返回 true，权限不足时返回 false;
 */
authenticator.hasPermissionRevoke = (params) => {
    return authenticator.hasPermission({...params, permissionOptCode: 'revoke'});
}


/**
 * 是否具有改单权限
 * @param params
 * @returns {boolean} 当有权限时返回 false，权限不足时返回 true;
 */
authenticator.hasPermissionChange = (params) => {
    return authenticator.hasPermission({...params, permissionOptCode: 'change'});
}

/**
 * 是否具有受理权限
 * @param params
 * @returns {boolean} 当有权限时返回 true，权限不足时返回 false;
 */
authenticator.hasPermissionHandle = (params) => {
    return authenticator.hasPermission({...params, permissionOptCode: 'handle'});
}

/**
 * 是否没有对应权限
 * @param params
 * @returns {boolean} 当有权限时返回 false，权限不足时返回 true;
 */
authenticator.isPermissionDenied = (params) => {
    return !authenticator.hasPermission(params);
}

/**
 * 是否没有查询权限
 * @param params
 * @returns {boolean} 当有权限时返回 false，权限不足时返回 true;
 */
authenticator.isPermissionDeniedQuery = (params) => {
    return !authenticator.hasPermission({...params, permissionOptCode: 'query'});
}

/**
 * 是否没有编制权限
 * @param params
 * @returns {boolean} 当有权限时返回 false，权限不足时返回 true;
 */
authenticator.isPermissionDeniedEdit = (params) => {
    return !authenticator.hasPermission({...params, permissionOptCode: 'edit'});
}

/**
 * 是否没有审核权限
 * @param params
 * @returns {boolean} 当有权限时返回 false，权限不足时返回 true;
 */
authenticator.isPermissionDeniedVerify = (params) => {
    return !authenticator.hasPermission({...params, permissionOptCode: 'verify'});
}

/**
 * 是否没有取消审核权限
 * @param params
 * @returns {boolean} 当有权限时返回 false，权限不足时返回 true;
 */
authenticator.isPermissionDeniedRevoke = (params) => {
    return !authenticator.hasPermission({...params, permissionOptCode: 'revoke'});
}

/**
 * 是否没有改单权限
 * @param params
 * @returns {boolean} 当有权限时返回 false，权限不足时返回 true;
 */
authenticator.isPermissionDeniedChange = (params) => {
    return !authenticator.hasPermission({...params, permissionOptCode: 'change'});
}

/**
 * 是否没有受理权限
 * @param params
 * @returns {boolean} 当有权限时返回 false，权限不足时返回 true;
 */
authenticator.isPermissionDeniedHandle = (params) => {
    return !authenticator.hasPermission({...params, permissionOptCode: 'handle'});
}


export default authenticator;
