import {useMemo, useState} from 'react'
import {nanoid} from 'nanoid'

const getKey = (dataIndex) => Array.isArray(dataIndex) ?
    dataIndex.join('.') : dataIndex

const findIndex = (state, key) => state.findIndex(([k]) => k === key)

const makeHas = (state) => {
    return (dataIndex) => {
        const key = getKey(dataIndex)
        return -1 < findIndex(state, key)
    }
}

const makeSet = (setState) => {
    return (dataIndex, fn, getValue) => {
        const key = getKey(dataIndex)

        setState((state) => {
            const i = findIndex(state, key)

            if (fn) {
                // 修改
                if (-1 < i) {
                    return [
                        ...state.slice(0, i),
                        [key, fn, getValue],
                        ...state.slice(i + 1),
                    ]
                }
                // 新增
                else {
                    return [
                        ...state,
                        [key, fn, getValue],
                    ]
                }
            }
            // 删除
            else if (-1 < i) {
                return [
                    ...state.slice(0, i),
                    ...state.slice(i + 1),
                ]
            }
            else {
                return state
            }
        })
    }
}

const useRows = ({childrenColumnName, dataSource, rowKey, setRowNum}) => {
    const [filters, setFilters] = useState([])
    const [sorts, setSorts] = useState([])
    const [rowKeyToIndex, setRowKeyToIndex] = useState(new Map())

    const rows = useMemo(
        () => {
            const assignRowKey = ({
                [childrenColumnName]: children,
                ...row
            }) => {
                if (
                    '$id' === rowKey &&
                    ! row[rowKey]
                ) {
                    row[rowKey] = nanoid()
                }

                if (children) {
                    row[childrenColumnName] = children.map(assignRowKey)
                }

                return row
            }

            return dataSource.map(assignRowKey)
        },

        [childrenColumnName, dataSource, rowKey]
    )

    const refinedRows = useMemo(
        () => {
            // 排序决定了行号，故需先排序再过滤
            const sortedRows = (() => {
                if (0 < sorts.length) {
                    const sortRows = (rows) => rows
                        .map((row) => {
                            if (row[childrenColumnName]) {
                                return {
                                    ...row,

                                    [childrenColumnName]: sortRows(
                                        row[childrenColumnName]
                                    ),
                                }
                            }
                            else {
                                return row
                            }
                        })
                        .slice()
                        .sort((a, b) => {
                            for (const [, sort, getValue] of sorts) {
                                const valueA = getValue(a)
                                const valueB = getValue(b)
                                const result = sort(valueA, valueB)

                                if (0 !== result) {
                                    return result
                                }
                            }

                            return 0
                        })

                    return sortRows(rows)
                }
                else {
                    return rows
                }
            })()

            for (const [index, {[rowKey]: rk}] of sortedRows.entries()) {
                setRowNum(rk, index + 1)
            }

            // TODO: 处理子行
            const fixedRowPairs = []
            const rowsToRefine = []

            for (const row of sortedRows) {
                const rk = row[rowKey]
                const index = rowKeyToIndex.get(rk)

                if (undefined !== index) {
                    fixedRowPairs.push([row, index])
                }
                else {
                    rowsToRefine.push(row)
                }
            }

            const refinedRows = (() => {
                if (0 < filters.length) {
                    const refineRows = (rows) => {
                        const refinedRows = []

                        for (
                            const {
                                [childrenColumnName]: children = [],
                                ...row
                            } of rows
                        ) {
                            const refinedChildren = refineRows(children)

                            if (0 < refinedChildren.length) {
                                refinedRows.push({
                                    ...row,
                                    [childrenColumnName]: refinedChildren,
                                })
                            }
                            else {
                                const isPassed = filters.every(
                                    ([, filter, getValue]) => {
                                        const value = getValue(row)
                                        return filter(value)
                                    }
                                )

                                if (isPassed) {
                                    refinedRows.push(row)
                                }
                            }
                        }

                        return refinedRows
                    }

                    return refineRows(rowsToRefine)
                }
                else {
                    return rowsToRefine
                }
            })()

            fixedRowPairs.sort(([, ia], [, ib]) => ia - ib)

            for (const [row, index] of fixedRowPairs) {
                refinedRows.splice(index, 0, row)
            }

            return refinedRows
        },

        [childrenColumnName, filters, rowKeyToIndex, rows, sorts]
    )

    const setFilter = (dataIndex, fn, getValue) => {
        const key = getKey(dataIndex)

        setFilters((filters) => {
            const i = findIndex(filters, key)

            if (fn) {
                // 修改
                if (-1 < i) {
                    return [
                        ...filters.slice(0, i),
                        [key, fn, getValue],
                        ...filters.slice(i + 1),
                    ]
                }
                // 新增
                else {
                    return [
                        ...filters,
                        [key, fn, getValue],
                    ]
                }
            }
            // 删除
            else if (-1 < i) {
                return [
                    ...filters.slice(0, i),
                    ...filters.slice(i + 1),
                ]
            }
            else {
                return filters
            }
        })

        setRowKeyToIndex(new Map())
    }

    const setSort = (dataIndex, fn, getValue) => {
        if (fn) {
            const key = getKey(dataIndex)
            setSorts([[key, fn, getValue]])
        }
        else {
            setSorts([])
        }

        setRowKeyToIndex(new Map())
    }

    return {
        clearFilters: () => setFilters([]),
        clearSorts: () => setSorts([]),
        filters,
        hasFilter: makeHas(filters),
        hasSort: makeHas(sorts),
        refinedRows,
        rows,
        setFilter,
        setRowKeyToIndex,
        setSort,
        //setFilter: makeSet(setFilters),
        //setSort: makeSet(setSorts),
        sorts,
    }
}

export default useRows
