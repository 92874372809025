/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react';
import {Button, Form, Typography,} from 'antd';
import {useNavigate} from 'react-router-dom';
import PURPRODPC from "./PURPRODPC";
import PURPRODPCEN from "./PURPRODPCEN";
import PURPRODPCGAR from "./PURPRODPCGAR";
import PURPRODPCGAREN from "./PURPRODPCGAREN";
import PURPRODPCPP from "./PURPRODPCPP";
import PURPRODPCPPEN from "./PURPRODPCPPEN";
import PURMATPCCN from "./PURMATPCCN"
import PURMATPCEN from "./PURMATPCEN"
import EPIMALTER from "./EPIMALTER";

const {Text} = Typography;

const Pane = () => {
    const [loading, setLoading] = useState(false);
    const [spinTip, setSpinTip] = useState('')
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const [param, setParam] = useState({});

    // 合同二维码
    const [pcQrCode, setPcQrCode] = useState(null);

    // 获取请求URL中的参数
    const getUrlParam = () => {
        let url = String(window.document.location.href);
        /*
            分析：
                最前面是?或&，紧跟着除 ?&#以外的字符若干，然后再等号，最后再跟着除 ?&#以外的字符，并且要分组捕获到【除?&#以外的字符】
        */
        var reg=/[?&]([^?&#]+)=([^?&#]+)/g;
        var param={};
        var ret =  reg.exec(url);
        while(ret){//当ret为null时表示已经匹配到最后了，直接跳出
            param[ret[1]]=ret[2];
            ret = reg.exec(url);
        }
        return param;
    }

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const param = getUrlParam();
        setParam(param)
    }

    const doPrint = () => {
        // 打印的整体思路是：获取body的内容进行存储，然后获取打印区域的内容填充到body中，进行打印，打印完成后，再将之前存储的body内容重新赋值给body即可。
        // windown.print()默认打印的内容是body的内容。
        let oldStr = window.document.body.innerHTML;
        let newStr = document.getElementById('printContent').innerHTML;
        window.document.body.innerHTML = newStr;
        window.print();
        //window.document.body.innerHTML = oldStr;
        window.location.reload();
    }

    return (
        <div style={{height: "100%", margin: "auto", width: "1075px", position: "relative"}}>
            {/*<Button size="small" className={"no-print"} type="primary" onClick={() => doPrint()}
                    style={{position: "absolute", right: "0px"}}>打印</Button>*/}
            {param?.rtCode == 'pur_pc_prod' &&<PURPRODPC pcId={param?.billId}/>}
            {param?.rtCode == 'pur_pc_prod_en' &&<PURPRODPCEN pcId={param?.billId}/>}
            {param?.rtCode == 'pur_pc_prod_gar' &&<PURPRODPCGAR pcId={param?.billId}/>}
            {param?.rtCode == 'pur_pc_prod_gar_en' &&<PURPRODPCGAREN pcId={param?.billId}/>}
            {param?.rtCode == 'pur_pc_prod_pp' &&<PURPRODPCPP pcId={param?.billId}/>}
            {param?.rtCode == 'pur_pc_prod_pp_en' &&<PURPRODPCPPEN pcId={param?.billId}/>}
            {param?.rtCode == 'pur_pc_mat_cn' &&<PURMATPCCN pcId={param?.billId}/>}
            {param?.rtCode == 'pur_pc_mat_en' &&<PURMATPCEN pcId={param?.billId}/>}
            {param?.rtCode == 'epim_alter' &&<EPIMALTER mmEpimAlterId={param?.billId}/>}
        </div>
    )

}

export default Pane;
