/** @jsxImportSource @emotion/react */
import {useEffect, useState} from 'react'
import Tools from "script/sweet-tools.mjs";
import './print.css'
import {log} from "mathjs";
import moment from "moment/moment";


const EPIMALTER = ({mmEpimAlterId}) => {

    const dateFormat = 'YYYY年MM月DD日';
    //变更记录主数据
    const [epim, setEpim] = useState({})
    //出口数量变更
    const [pnumList, setPnumList] = useState([])
    //进口数量变更
    const [mnumList, setMnumList] = useState([])
    //单耗变更类型的款号集合
    const [styleNoList, setStyleNoList] = useState([]);
    //单耗变更类型的物料集合
    const [alterMrowList, setAlterMrowList] = useState([]);
    //单耗变更类型记录
    const [consumptionList, setConsumptionList] = useState([]);
    //品名规格变更
    const [specList, setSpecList] = useState([]);
    //海关编码变更
    const [hsAlterList, setHsAlterList] = useState([]);

    //发生出口数量变更的现手册金额总和，原手册金额总和，增加金额总和
    const [epimPamtTotal, setEpimPamtTotal] = useState(0);
    const [originEpimPamtTotal, setOriginEpimPamtTotal] = useState(0);
    const [addPamtTotal, setAddPamtTotal] = useState(0);

    //发生进口数量变更的现手册金额总和，原手册金额总和，增加金额总和
    const [epimRowAmtTotal, setEpimRowAmtTotal] = useState(0);
    const [originEpimRowAmtTotal, setOriginEpimRowAmtTotalTotal] = useState(0);
    const [addRowAmtTotal, setAddRowAmtTotal] = useState(0);

    const [tableCount, setTableCount] = useState([0, 0, 0, 0, 0])

    useEffect(() => {
        if (mmEpimAlterId) {
            (async () => {
                await Promise.all([
                    init()
                    , initPnumList()
                    , initMnumList()
                    , initUsageProwList()
                    , initUsageMrowList()
                    , initUsageConsumptionList()
                    , initSpecList()
                    , initHsAltList()
                ]).then((results) => {
                    let epim = results[0];
                    let pnumList = results[1];
                    let mnumList = results[2];
                    let alterProwList = results[3];
                    let alterMrowList = results[4];
                    let consumptionList = results[5];
                    let specList = results[6];
                    let hsAlterList = results[7];

                    if (pnumList) {
                        let epimPamtTotal = 0;
                        let originEpimPamtTotal = 0;
                        let addPamtTotal = 0;
                        pnumList.forEach(i => {
                            epimPamtTotal += i.epimPamt;
                            originEpimPamtTotal += i.originEpimPamt;
                            addPamtTotal += i.addPamt;
                        })
                        setEpimPamtTotal(epimPamtTotal)
                        setOriginEpimPamtTotal(originEpimPamtTotal)
                        setAddPamtTotal(addPamtTotal)
                    }

                    if (mnumList) {
                        let epimRowAmtTotal = 0;
                        let originEpimRowAmtTotal = 0;
                        let addRowAmtTotal = 0;
                        mnumList.forEach(i => {
                            epimRowAmtTotal += i.epimRowAmt;
                            originEpimRowAmtTotal += i.originEpimRowAmt;
                            addRowAmtTotal += i.addRowAmt;
                        })
                        setEpimRowAmtTotal(epimRowAmtTotal)
                        setOriginEpimRowAmtTotalTotal(originEpimRowAmtTotal)
                        setAddRowAmtTotal(addRowAmtTotal)
                    }


                    setEpim(epim);
                    setPnumList(pnumList);
                    setMnumList(mnumList);
                    setSpecList(specList);
                    setHsAlterList(hsAlterList);

                    //单耗变更 三个一组 如果不满三个 补充一个用来占位以免每列宽度样式超长
                    if (alterProwList) {
                        alterProwList.map(styleNos => {
                            if (styleNos.length < 3) {
                                // for (let i = 0; i <= 3-styleNos.length; i++) {
                                styleNos.push({
                                    porStyleNo: '',
                                    epimProwNo: '',
                                    epimsnProwTotalId: '',
                                })
                                // }
                            }
                        })
                    }
                    setStyleNoList(alterProwList);
                    setAlterMrowList(alterMrowList);
                    setConsumptionList(consumptionList);


                    //表格序号
                    let tableCount = [0, 0, 0, 0, 0]
                    let count = 0;
                    if (pnumList.length > 0) {
                        count++;
                        tableCount[0] = count;
                    }
                    if (mnumList.length > 0) {
                        count++;
                        tableCount[1] = count;
                    }
                    if (consumptionList.length > 0) {
                        count++;
                        tableCount[2] = count;
                    }
                    if (specList.length > 0) {
                        count++;
                        tableCount[3] = count;
                    }
                    if (hsAlterList.length > 0) {
                        count++;
                        tableCount[4] = count;
                    }


                    setTableCount(tableCount);
                });
            })()
        }
    }, [mmEpimAlterId]);

    //查询变更记录
    const init = async () => {
        console.log(mmEpimAlterId);
        return await Tools.runAsync(async () => {
            return await Tools.http.post('/mm/epim/oneMmEpimAlterForPrint', {mmEpimAlterId: mmEpimAlterId});
        }, {
            error: {content: (err) => `查询失败: ${err.message}`}, loading: {show: false}, success: {show: false},
        });
    }
    //查询出口数量变更类型的记录明细
    const initPnumList = async () => {
        return await Tools.runAsync(async () => {
            return await Tools.http.post('/mm/epim/listMmEpimAlterRowForPrint', {
                mmEpimAlterId: mmEpimAlterId,
                alterRowType: 'PNUM'
            });
        }, {
            error: {content: (err) => `查询失败: ${err.message}`}, loading: {show: false}, success: {show: false},
        });
    }
    //查询进口口数量变更类型的记录明细
    const initMnumList = async () => {
        return await Tools.runAsync(async () => {
            return await Tools.http.post('/mm/epim/listMmEpimAlterRowForPrint', {
                mmEpimAlterId: mmEpimAlterId,
                alterRowType: 'MNUM'
            });
        }, {
            error: {content: (err) => `查询失败: ${err.message}`}, loading: {show: false}, success: {show: false},
        });
    }

    //查询单耗变更类型的记录明细的款号
    const initUsageProwList = async () => {
        return await Tools.runAsync(async () => {
            return await Tools.http.post('/mm/epim/listMmEpimAlterRowProwForPrint', {
                mmEpimAlterId: mmEpimAlterId,
                alterRowType: 'USAGE'
            });
        }, {
            error: {content: (err) => `查询失败: ${err.message}`}, loading: {show: false}, success: {show: false},
        });
    }
    const initUsageMrowList = async () => {
        return await Tools.runAsync(async () => {
            return await Tools.http.post('/mm/epim/listMmEpimAlterRowMrowForPrint', {
                mmEpimAlterId: mmEpimAlterId,
                alterRowType: 'USAGE'
            });
        }, {
            error: {content: (err) => `查询失败: ${err.message}`}, loading: {show: false}, success: {show: false},
        });
    }
    const initUsageConsumptionList = async () => {
        return await Tools.runAsync(async () => {
            return await Tools.http.post('/mm/epim/listMmEpimAlterRowConsumptionForPrint', {
                mmEpimAlterId: mmEpimAlterId,
                alterRowType: 'USAGE'
            });
        }, {
            error: {content: (err) => `查询失败: ${err.message}`}, loading: {show: false}, success: {show: false},
        });
    }

    //查询品名规格变更类型的记录明细
    const initSpecList = async () => {
        return await Tools.runAsync(async () => {
            return await Tools.http.post('/mm/epim/listMmEpimAlterRowForPrint', {
                mmEpimAlterId: mmEpimAlterId,
                alterRowType: 'PSPEC'
            });
        }, {
            error: {content: (err) => `查询失败: ${err.message}`}, loading: {show: false}, success: {show: false},
        });
    }
    //查询海关编码变更类型的记录明细
    const initHsAltList = async () => {
        return await Tools.runAsync(async () => {
            return await Tools.http.post('/mm/epim/listMmEpimAlterRowForPrint', {
                mmEpimAlterId: mmEpimAlterId,
                alterRowType: 'PNO'
            });
        }, {
            error: {content: (err) => `查询失败: ${err.message}`}, loading: {show: false}, success: {show: false},
        });
    }

    const printCss = {
        /*@media print {
                    body {zoom: 0.62}
                        .no-print{
                display:none;
            }
        }*/

        "@media print": {
            "body": {zoom: "0.62"}
            //"& .print-container": {
            //    overflow: "hidden"
            //},
            /*"& .order-edit-content":{
                display: "none"
            }*/
        },
        // 去除页眉页脚
        //"@page": {
        //    marginBottom: "0mm",
        //    marginTop: "0mm"
        //},
        /*display: "flex",
        flexDirection: "column",
        width: "1075px",
        margin: "auto",*/
        //height: "100vh",
        //overflow: "auto",
    }

    const tdcenter = {
        textAlign: 'center'
    }
    const tdleft = {
        textAlign: 'left'
    }
    const tdright = {
        textAlign: 'right'
    }
    const border = {
        border: '1px solid #000'
    }
    const borderBottom = {
        borderBottom: '1px solid #000'
    }

    //数量变更类型的表头
    const numTr = (
        <tr>
            <th style={{width: "10%", ...tdcenter, ...border}}>项号</th>
            <th style={{width: "24%", ...tdcenter, ...border}}>品名</th>
            <th style={{width: "8%", ...tdcenter, ...border}}>原数量</th>
            <th style={{width: "8%", ...tdcenter, ...border}}>增加数量</th>
            <th style={{width: "8%", ...tdcenter, ...border}}>现数量</th>
            <th style={{width: "6%", ...tdcenter, ...border}}>单位</th>
            <th style={{width: "12%", ...tdcenter, ...border}}>原金额</th>
            <th style={{width: "12%", ...tdcenter, ...border}}>增加金额</th>
            <th style={{width: "12%", ...tdcenter, ...border}}>现金额</th>
        </tr>
    )


    return (
        <div id={"printContent"} css={printCss} style={{height: '100%'}}>
            <div className={"print-content"}>
                <div className="order-edit-content" style={{width: "1075px"}}>
                    <table width="1075" className="print-table">
                        <thead>
                        <tr>
                            <td>
                                <div style={{paddingTop: "15px"}}>
                                    <div style={{
                                        textAlign: "center",
                                        fontSize: "22px",
                                        fontWeight: "bold"
                                    }}>申请变更
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <div style={{padding: "10px 0px", width: "1075px"}}>
                                    <div className="l"><b>{epim?.customName}：</b></div>
                                    <div className="clear"></div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style={{padding: "10px 0px", width: "1075px", textIndent: '2em'}}>
                                    <div className="l">
                                        <b>我司手册 {epim?.epimRlsNo} 因 {epim?.altReason} 原因。故申请变更,具体如下:</b>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {

                        pnumList.length > 0 &&
                        <>
                            <div style={{padding: "30px 0px", width: "1075px"}}>
                                <div className="l"><b>{tableCount[0]}、出口变更</b></div>
                                <div className="clear"></div>
                            </div>
                            <table width="1075" className="print-table">
                                <thead>
                                {numTr}
                                </thead>
                                <tbody>

                                {
                                    pnumList.map(i => {
                                        return (
                                            <tr>
                                                <td style={{...tdcenter, ...border}}>{i.epimProwNo}</td>
                                                <td style={{...tdcenter, ...border}}>{i.goodsNameCn}</td>
                                                <td style={{...tdright, ...border}}>{i.originEpimPqty}</td>
                                                <td style={{...tdright, ...border}}>{i.addPqty}</td>
                                                <td style={{...tdright, ...border}}>{i.epimPqty}</td>
                                                <td style={{...tdcenter, ...border}}>{i.uomName}</td>
                                                <td style={{...tdright, ...border}}>{i.originEpimPamt}</td>
                                                <td style={{...tdright, ...border}}>{i.addPamt}</td>
                                                <td style={{...tdright, ...border}}>{i.epimPamt}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>

                                    <td style={{...tdright, ...border}}>{epimPamtTotal}</td>
                                    <td style={{...tdright, ...border}}>{addPamtTotal}</td>
                                    <td style={{...tdright, ...border}}>{originEpimPamtTotal}</td>
                                </tr>
                                <tr>
                                    <td style={{...borderBottom}}><b>出口总金额</b></td>
                                    <td style={{...borderBottom}}/>
                                    <td style={{...borderBottom}}/>
                                    <td style={{...borderBottom}}/>
                                    <td style={{...borderBottom}}/>
                                    <td style={{...borderBottom}}/>

                                    <td style={{...tdright, ...border}}>{epim?.initpmt}</td>
                                    <td style={{...tdright, ...border}}>{epim?.addpmt}</td>
                                    <td style={{...tdright, ...border}}>{epim?.currpmt}</td>
                                </tr>
                                </tbody>
                            </table>
                        </>
                    }
                    {
                        mnumList.length > 0 &&
                        <>
                            <div style={{padding: "30px 0px", width: "1075px"}}>
                                <div className="l"><b>{tableCount[1]}、进口变更</b></div>
                                <div className="clear"></div>
                            </div>
                            <table width="1075" className="print-table">
                                <thead>
                                {numTr}
                                </thead>
                                <tbody>
                                {
                                    mnumList.map(i => {
                                        return (
                                            <tr>
                                                <td style={{...tdcenter, ...border}}>{i.epimMrowNo}</td>
                                                <td style={{...tdcenter, ...border}}>{i.mrpDtlGoodsNameCn}</td>
                                                <td style={{...tdright, ...border}}>{i.originReqQty}</td>
                                                <td style={{...tdright, ...border}}>{i.addReqty}</td>
                                                <td style={{...tdright, ...border}}>{i.reqQty}</td>
                                                <td style={{...tdcenter, ...border}}>{i.uomName}</td>
                                                <td style={{...tdright, ...border}}>{i.originEpimRowAmt}</td>
                                                <td style={{...tdright, ...border}}>{i.addRowAmt}</td>
                                                <td style={{...tdright, ...border}}>{i.epimRowAmt}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td style={{...tdright, ...border}}>{epimRowAmtTotal}</td>
                                    <td style={{...tdright, ...border}}>{addRowAmtTotal}</td>
                                    <td style={{...tdright, ...border}}>{originEpimRowAmtTotal}</td>
                                </tr>
                                <tr>
                                    <td style={{...borderBottom}}><b>进口总金额</b></td>
                                    <td style={{...borderBottom}}/>
                                    <td style={{...borderBottom}}/>
                                    <td style={{...borderBottom}}/>
                                    <td style={{...borderBottom}}/>
                                    <td style={{...borderBottom}}/>
                                    <td style={{...tdright, ...border}}>{epim?.initRowAmt}</td>
                                    <td style={{...tdright, ...border}}>{epim?.addRowAmt}</td>
                                    <td style={{...tdright, ...border}}>{epim?.currRowAmt}</td>
                                </tr>
                                </tbody>
                            </table>
                        </>
                    }

                    {
                        alterMrowList.length > 0 &&
                        <>
                            <div style={{padding: "30px 0px", width: "1075px"}}>
                                <div className="l"><b>{tableCount[2]}、单耗变更</b></div>
                                <div className="clear"></div>
                            </div>

                            {
                                styleNoList.map(styleNos => {
                                    return (
                                        <>
                                            <table width="1075" className="print-table"
                                                   style={{margin: '0px 0px 30px 0px'}}>
                                                <thead>
                                                <tr>
                                                    <th rowSpan={2}
                                                        style={{width: "10%", ...tdcenter, ...border}}>项号
                                                    </th>
                                                    <th rowSpan={2}
                                                        style={{width: "24%", ...tdcenter, ...border}}>品名
                                                    </th>
                                                    {
                                                        styleNos.map(styleNo => {
                                                            if (styleNo.porStyleNo) {
                                                                return (
                                                                    <th colSpan={2}
                                                                        style={{width: "22%", ...tdcenter, ...border}}>{styleNo.epimProwNo}</th>
                                                                );
                                                            } else {
                                                                return (
                                                                    <th colSpan={2}></th>
                                                                );
                                                            }
                                                        })
                                                    }

                                                </tr>
                                                <tr>
                                                    {
                                                        styleNos.map(styleNo => {
                                                            if (styleNo.porStyleNo) {

                                                                return (
                                                                    <>
                                                                        <th style={{...tdcenter, ...border}}>原单耗</th>
                                                                        <th style={{...tdcenter, ...border}}>现单耗</th>
                                                                    </>
                                                                );
                                                            } else {

                                                                return (
                                                                    <>
                                                                        <th></th>
                                                                    </>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    alterMrowList.map(mrow => {
                                                        let mmEpimAlterRowIdList = mrow.mmEpimAlterRowIds.split(',');
                                                        return (
                                                            <tr>
                                                                <td style={{...tdcenter, ...border}}>{mrow.epimMrowNo}</td>
                                                                <td style={{...tdcenter, ...border}}>{mrow.mrpDtlGoodsNameCn}</td>
                                                                {
                                                                    styleNos.map(styleNo => {
                                                                        if (styleNo.porStyleNo) {
                                                                            let consumption = consumptionList.find(consumption => {
                                                                                return consumption.porStyleNo === styleNo.porStyleNo && mmEpimAlterRowIdList.includes(consumption.mmEpimAlterRowId)
                                                                            });
                                                                            return (
                                                                                <>
                                                                                    <td style={{...tdcenter, ...border}}>{consumption?.originEpimConsumeRate}</td>
                                                                                    <td style={{...tdcenter, ...border}}>{consumption?.epimConsumeRate}</td>
                                                                                </>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <>
                                                                                    <td></td>
                                                                                </>
                                                                            );
                                                                        }

                                                                    })
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </>
                                    );
                                })
                            }
                        </>
                    }

                    {
                        specList.length > 0 &&
                        <>
                            <div style={{padding: "30px 0px", width: "1075px"}}>
                                <div className="l"><b>{tableCount[3]}、品名、规格变更:</b></div>
                                <div className="clear"></div>
                            </div>
                            <table width="1075" className="print-table">
                                <thead>
                                <tr>
                                    <th style={{width: "10%", ...tdcenter, ...border}}>项号</th>
                                    <th style={{width: "24%", ...tdcenter, ...border}}>原品名</th>
                                    <th style={{width: "24%", ...tdcenter, ...border}}>现品名</th>
                                    <th style={{width: "18%", ...tdcenter, ...border}}>原规格</th>
                                    <th style={{width: "18%", ...tdcenter, ...border}}>现规格</th>
                                    <th style={{width: "8%", ...tdcenter, ...border}}>有否进/出过料</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    specList.map(i => {
                                        return (
                                            <tr>
                                                <td style={{...tdcenter, ...border}}>{i.alterRowType === 'PSPEC' ? i.epimProwNo : i.epimMrowNo}</td>
                                                <td style={{...tdcenter, ...border}}>{i.alterRowType === 'PSPEC' ? i.originPbosCdNameCn : i.originMmCdNameCn}</td>
                                                <td style={{...tdcenter, ...border}}>{i.alterRowType === 'PSPEC' ? i.pbosCdNameCn : i.mmCdNameCn}</td>
                                                <td style={{...tdleft, ...border}}>{i.originFabricComp}</td>
                                                <td style={{...tdleft, ...border}}>{i.fabricComp}</td>
                                                <td style={{...tdcenter, ...border}}>{i.alterRowType === 'PSPEC' ? i.hasExportedFlag ? '已出口' : '未出口' : i.hasImportedFlag ? '已进口' : '未进口'}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </>
                    }

                    {
                        hsAlterList.length > 0 &&
                        <>
                            <div style={{padding: "30px 0px", width: "1075px"}}>
                                <div className="l"><b>{tableCount[4]}、编码变更:</b></div>
                                <div className="clear"></div>
                            </div>
                            <table width="1075" className="print-table">
                                <thead>
                                <tr>
                                    <th style={{width: "10%", ...tdcenter, ...border}}>项号</th>
                                    <th style={{width: "24%", ...tdcenter, ...border}}>品名</th>
                                    <th style={{width: "10%", ...tdcenter, ...border}}>原商品编码</th>
                                    <th style={{width: "10%", ...tdcenter, ...border}}>现商品编码</th>
                                    <th style={{width: "15%", ...tdcenter, ...border}}>现规格</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    hsAlterList.map(i => {
                                        return (
                                            <tr>
                                                <td style={{...tdcenter, ...border}}>{i.alterRowType === 'PNO' ? i.epimProwNo : i.epimMrowNo}</td>
                                                <td style={{...tdcenter, ...border}}>{i.alterRowType === 'PNO' ? i.originPbosCdNameCn : i.originMmCdNameCn}</td>
                                                <td style={{...tdcenter, ...border}}>{i.alterRowType === 'PNO' ? i.originPHsCode : i.originMmHsCode}</td>
                                                <td style={{...tdcenter, ...border}}>{i.alterRowType === 'PNO' ? i.pHsCode : i.hsCode}</td>
                                                <td style={{...tdleft, ...border}}>{i.alterRowType === 'PNO' ? i.fabricComp : i.goodsSpecDescCn}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </>
                    }
                    <div style={{padding: "50px 0px", width: "1075px"}}>
                    </div>
                    <table width="1075" className="print-table">
                        <thead>
                        <tr>
                            <th style={{width: "20%", ...tdcenter}}>
                                希批准为盼！
                            </th>
                            <th style={{width: "20%", ...tdcenter}}></th>
                            <th style={{width: "20%", ...tdcenter}}></th>
                            <th style={{width: "30%", ...tdcenter}}></th>
                            <th style={{width: "10%", ...tdcenter}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{...tdcenter}}>{epim?.coNameCn}</td>
                            <td></td>
                        </tr>
                        <tr>
                        <td></td>
                            <td></td>
                            <td></td>
                            <td style={{...tdcenter}}>{moment(new Date()).format(dateFormat)}</td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default EPIMALTER;
