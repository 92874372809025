/** @jsxImportSource @emotion/react */
;
import {useEffect, useState} from 'react';
import {Select} from 'antd';
import Dialog from 'components/Bizs/PuModal/PuModal.jsx'
import SuffixIcon from 'components/SuffixIcon/SuffixIcon'
import useGlobalData from "hooks/useGlobalData.mjs";
import Tools from "../../../script/sweet-tools.mjs";
/**
 * 权限组选择组件
 *
 * @param extra 自定义扩展属性
 * @param props Input 组件原生属性
 * billTypeCode 单据类型编码，若传值，将检查该单据是否需要权限组编码，若是，返回核算将剔除编码为空的记录
 * @returns {JSX.Element}
 * @constructor
 */
const Pane = ({uuDataIsolation = true, permissionCode, permissionOptCode = "query", billTypeCode , searchParam = {}, searchMode = "default", value, onChange, onExtraChange, selectProps, multiple = false, disabled, ...props}) => {
    const {Option} = Select;

    //数据源
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);

    //打开弹窗
    const showModal = () => {
        setVisible(true)
    };

    //弹窗确定回调
    const onOk = (values, record) => {
        if (onChange) {
            if (multiple) {
                onChange(values);
            } else {
                onChange(values[0]);
            }
        }
        if (onExtraChange) {
            if (multiple) {
                onExtraChange(record)
            } else {
                onExtraChange(record[0])
            }
        }
        setVisible(false);
    };

    const onSelecChange = (values) => {
        if (onChange) {
            onChange(values)
        }
        if (onExtraChange) {
            if (multiple) {
                const records = data.filter(item => values.indexOf(item.puId) > -1)
                onExtraChange(records)
            } else {
                const record = data.filter(item => item.puId == values);
                onExtraChange(record[0]);
            }
        }
    }

    //弹窗取消回调
    const onCancel = () => {
        setVisible(false);
    };

    useEffect(() => {
        let puData = useGlobalData.getPus();
        let data = [];
        if ( billTypeCode ){
            let billTypes = useGlobalData.getDictDtls("BILL_TYPE");
            let needPuNoBillTypes = billTypes.filter(item=>{
                return (item.dictItemCode === billTypeCode && item.memo ==='NEED_PU_NO');
            });
            if ( needPuNoBillTypes && needPuNoBillTypes.length > 0){
                data = puData.filter(item => {
                    return ( item.puCode !=null && item.puCode !='')
                });
            }
        }else{
            data = puData;
        }
        if (permissionCode && permissionOptCode) {
            setData(data.filter(item => {
                return Tools.authenticator.checkComponentPermissionScope({
                    permissionCode,
                    permissionOptCode,
                    sourceObject: item,
                    sourceType: 'PU'
                })
            }))

        } else {
            setData(data);
        }
    }, [])

    //------ Modal 部分 End ------
    return (
        <>
            <SuffixIcon onClick={showModal} disabled={disabled}>
                <Select
                    style={{overflow: 'hidden'}}
                    mode={multiple && "multiple"}
                    showSearch
                    disabled={disabled}
                    optionFilterProp="children"
                    onChange={onSelecChange}
                    allowClear
                    value={value}
                    filterOption={(input, option) =>
                        option && option.children && input ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                    }
                    optionLabelProp="label"
                    {...selectProps}
                >
                    {data.map(item => <Option key={item.puId} label={item.puName}
                                              value={item.puId}>{item.puName}</Option>)}
                </Select>
            </SuffixIcon>
            <Dialog
                uuDataIsolation={uuDataIsolation}
                permissionCode={permissionCode}
                permissionOptCode={permissionOptCode}
                value={multiple ? value ?? [] : (value ? [value] : [])}
                visible={visible}
                multiple={multiple}
                onOk={onOk}
                onCancel={onCancel}
                searchParam={searchParam}
            />
        </>
    )
}
export default Pane;
